.container {
  /* stylelint-disable-line block-no-empty */
}

.overlay {
  /* stylelint-disable-line block-no-empty */
}

/* Enter */
:global(.enter) > .container {
  transform: translateX(-300px);
}

:global(.enter) > .overlay {
  opacity: 0;
}

/* Enter active */
:global(.enter-active) > .container {
  transform: translateX(0);
}

:global(.enter-done) > .overlay,
:global(.enter-active) > .overlay {
  opacity: 0.75;
}

/* Exit */
:global(.exit) > .container {
  transform: translateX(0);
}

:global(.exit) > .overlay {
  opacity: 0.75;
}

/* Exit active */
:global(.exit-active) > .container {
  transform: translateX(-300px);
}

:global(.exit-done) > .overlay,
:global(.exit-active) > .overlay {
  opacity: 0;
}
