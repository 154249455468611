.wave {
  transform: translateX(-100%);
  background-image: linear-gradient(to right, transparent, #000, transparent);
  animation-name: loading-bar-wave;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes loading-bar-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
